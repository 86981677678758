import React from 'react'
import * as HeaderStyles from './Header.module.scss'
import Nav from '../Nav/Nav'
// import GoHome from '../GoHome/GoHome'
import TideHeaderLogo from '../HeaderLogo/TideHeaderLogo'

interface TideHeaderProps {
  homePage?: boolean
}

const TideHeader = ({ homePage }: TideHeaderProps) => {
  return (
    <>
      <div className={HeaderStyles.TideHeader}>
        {/* <GoHome /> */}
        <div className={HeaderStyles.NavWrapper}>
          <Nav site="tide" theme="light" />
        </div>
        {/* <div className={HeaderStyles.Logo}> */}
        <TideHeaderLogo homePage={homePage} absolute={true} />
        {/* </div> */}
        <div className={HeaderStyles.Hero}>
          <img src="http://dev.muddystilettos.co.uk/wp-content/uploads/2023/09/shutterstock_1491191690-for-Tide.jpg" />
        </div>
      </div>
      {homePage && (
        <div className={HeaderStyles.HeroTextMobile}>
          <span>Enter to Win</span>
          <span className={HeaderStyles.Span}>£10,000</span>
          {/* <span>for your business</span> */}
        </div>
      )}
    </>
  )
}

export default TideHeader
