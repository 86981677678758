// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import TideHeader from '../../components/BestSchoolsAwards/Header/TideHeader'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const WomenInBusiness = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <TideHeader homePage={true} />
      <BSAContent>
        <h1>Welcome</h1>
        <p>
          Sound the klaxon for women at work! If you’ve vowed to finally follow
          your business dream or turbo-charge your existing company into
          something bigger, this could be your life-changing moment.
        </p>
        <p>
          Muddy Stilettos is an indie business with a female founder
          (Editor-in-Chief Hero Brown started the brand at her kitchen table in
          2013), with an all-female management team, so we know first-hand what
          it’s like to have a dream, conjure an idea and work hard to nurture
          it.
        </p>
        <p>
          Ten years on from our own launch, we want to help more women achieve
          their own entrepreneurial goals, so we’re thrilled to be partnering
          with the leading business financial platform in the UK,
          <strong> Tide</strong>, whose own ambition is to help a phenomenal
          200,000 female-led companies take their first steps by 2027.
        </p>
        <p>
          What does this mean for you? Basically, the mother of an opportunity
          with the inaugural SHE MEANS BUSINESS Award. One very lucky female
          entrepreneur is about to win a lump sum of 
          <strong>£10,000 CASH</strong> to spend on her company, courtesy of
          Tide and Muddy Stilettos. Businesses from all sectors are welcome
          (lifestyle, service industries, tech, etc), from start-ups to
          established companies. Tell us what makes your business special, why
          you’re passionate about what you do, and the impact this huge cash
          injection will make to your company’s growth.
        </p>
        <p>
          <strong>THIS COMPETITION IS NOW CLOSED.</strong>
        </p>
        <p>
          The results are in! Find out who won by clicking{' '}
          <a href="https://muddystilettos.co.uk/women-in-business/winner">
            here
          </a>
          .
        </p>
        <p style={{ textAlign: 'center' }}>
          <Link
            to={`/women-in-business/how-it-works/`}
            className={`${ContentStyles.RegisterInterestButton} ${ContentStyles.Pink}`}
          >
            How It Works
          </Link>
        </p>
      </BSAContent>
      {/* <WhyGetInvolved /> */}
      <BSAFooter />
    </>
  )
}

export default WomenInBusiness
